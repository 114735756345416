/*================================================================================================================
*                                                  Rights
* ==============================================================================================================*/

// users
export const GET_USER_KEY = 'user';
export const GET_USERS_KEY = 'users';
export const UPSERT_USER_KEY = 'upsertUser';
export const LOGOUT_USERS_KEY = 'logoutUsers';
export const LOGOUT_ALL_USERS_KEY = 'logoutAllUsers';
export const DELETE_USER_KEY = 'deleteUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_GOOGLE_ACCOUNT = 'unlinkUserGoogleAccount';

// rights
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// roles
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';

/*================================================================================================================
*                                                  Crops
* ==============================================================================================================*/

export const GET_PLANNED_CROPS_KEY = 'plannedCrops';
export const UPSERT_PLANNED_CROP_KEY = 'upsertPlannedCrop';
export const DELETE_PLANNED_CROP_KEY = 'deletePlannedCrop';

export const GET_CROPS_KEY = 'crops';
export const UPSERT_CROP_KEY = 'upsertCrop';
export const DELETE_CROP_KEY = 'deleteCrop';

export const GET_CROP_VARIETIES_KEY = 'cropVarieties';
export const UPSERT_CROP_VARIETY_KEY = 'upsertCropVariety';
export const DELETE_CROP_VARIETY_KEY = 'deleteCropVariety';

/*================================================================================================================
*                                                  Master Data
* ==============================================================================================================*/

// Division
export const GET_DIVISIONS_KEY = 'divisions';
export const UPSERT_DIVISION_KEY = 'upsertDivision';
export const DELETE_DIVISION_KEY = 'deleteDivision';

// Subdivision
export const GET_SUBDIVISIONS_KEY = 'subdivisions';
export const UPSERT_SUBDIVISION_KEY = 'upsertSubdivision';
export const DELETE_SUBDIVISION_KEY = 'deleteSubdivision';

// Department
export const GET_DEPARTMENTS_KEY = 'departments';
export const UPSERT_DEPARTMENT_KEY = 'upsertDepartment';
export const DELETE_DEPARTMENT_KEY = 'deleteDepartment';

// Production Unit
export const GET_PRODUCTION_UNITS_KEY = 'productionUnits';
export const UPSERT_PRODUCTION_UNIT_KEY = 'upsertProductionUnit';
export const DELETE_PRODUCTION_UNIT_KEY = 'deleteProductionUnit';

// Field
export const GET_FIELDS_KEY = 'fields';
export const UPSERT_FIELD_KEY = 'upsertField';
export const DELETE_FIELD_KEY = 'deleteField';

// Block
export const GET_BLOCKS_KEY = 'blocks';
export const UPSERT_BLOCK_KEY = 'upsertBlock';
export const DELETE_BLOCK_KEY = 'deleteBlock';

// Commodity
export const GET_COMMODITIES_KEY = 'commodities';
export const UPSERT_COMMODITY_KEY = 'upsertCommodity';
export const DELETE_COMMODITY_KEY = 'deleteCommodity';

// Variety
export const GET_VARIETIES_KEY = 'varieties';
export const UPSERT_VARIETY_KEY = 'upsertVariety';
export const DELETE_VARIETY_KEY = 'deleteVariety';

// Project
export const GET_PROJECTS_KEY = 'projects';
export const UPSERT_PROJECT_KEY = 'upsertProject';
export const UPSERT_PROJECT_BLOCK_BRIDGE_KEY = 'upsertProjectBlockBridge';
export const DELETE_PROJECT_KEY = 'deleteProject';

// Activity
export const GET_ACTIVITIES_KEY = 'activities';
export const UPSERT_ACTIVITY_KEY = 'upsertActivity';
export const DELETE_ACTIVITY_KEY = 'deleteActivity';

// Activity Type
export const GET_ACTIVITY_TYPES_KEY = 'activityTypes';
export const UPSERT_ACTIVITY_TYPE_KEY = 'upsertActivityType';
export const DELETE_ACTIVITY_TYPE_KEY = 'deleteActivityType';

// Irrigation Cycle
export const GET_IRRIGATION_CYCLES_KEY = 'irrigationCycles';
export const UPSERT_IRRIGATION_CYCLE_KEY = 'upsertIrrigationCycle';
export const DELETE_IRRIGATION_CYCLE_KEY = 'deleteIrrigationCycle';

// Nursery
export const GET_NURSERIES_KEY = 'nurseries';
export const UPSERT_NURSERY_KEY = 'upsertNursery';
export const DELETE_NURSERY_KEY = 'deleteNursery';

// Plants Per Hectare
export const GET_PLANTS_PER_HECTARES_KEY = 'plantsPerHectares';
export const UPSERT_PLANTS_PER_HECTARE_KEY = 'upsertPlantsPerHectare';
export const DELETE_PLANTS_PER_HECTARE_KEY = 'deletePlantsPerHectare';

// Priority
export const GET_PRIORITIES_KEY = 'priorities';
export const GET_ALL_PRIORITIES_KEY = 'allPriorities';
export const UPSERT_PRIORITY_KEY = 'upsertPriority';
export const REORDER_PRIORITY_KEY = 'reorderPriority';
export const DELETE_PRIORITY_KEY = 'deletePriority';

// Rootstock
export const GET_ROOTSTOCKS_KEY = 'rootStocks';
export const UPSERT_ROOTSTOCK_KEY = 'upsertRootStock';
export const DELETE_ROOTSTOCK_KEY = 'deleteRootStock';

// Seed Tray Size
export const GET_SEED_TRAY_SIZES_KEY = 'seedTraySizes';
export const UPSERT_SEED_TRAY_SIZE_KEY = 'upsertSeedTraySize';
export const DELETE_SEED_TRAY_SIZE_KEY = 'deleteSeedTraySize';

// Spray Method
export const GET_SPRAY_METHODS_KEY = 'sprayMethods';
export const UPSERT_SPRAY_METHOD_KEY = 'upsertSprayMethod';
export const DELETE_SPRAY_METHOD_KEY = 'deleteSprayMethod';

// System
export const GET_SYSTEMS_KEY = 'systems';
export const UPSERT_SYSTEM_KEY = 'upsertSystem';
export const DELETE_SYSTEM_KEY = 'deleteSystem';

// Unit Of Measure
export const GET_UNIT_OF_MEASURES_KEY = 'unitOfMeasures';
export const UPSERT_UNIT_OF_MEASURE_KEY = 'upsertUnitOfMeasure';
export const DELETE_UNIT_OF_MEASURE_KEY = 'deleteUnitOfMeasure';

// Active Ingredient
export const GET_ACTIVE_INGREDIENTS_KEY = 'activeIngredients';
export const UPSERT_ACTIVE_INGREDIENT_KEY = 'upsertActiveIngredient';
export const DELETE_ACTIVE_INGREDIENT_KEY = 'deleteActiveIngredient';

// Chemical Category
export const GET_CHEMICAL_CATEGORIES_KEY = 'chemicalCategories';
export const UPSERT_CHEMICAL_CATEGORY_KEY = 'upsertChemicalCategory';
export const DELETE_CHEMICAL_CATEGORY_KEY = 'deleteChemicalCategory';

// Chemical Element
export const GET_CHEMICAL_ELEMENTS_KEY = 'chemicalElements';
export const UPSERT_CHEMICAL_ELEMENT_KEY = 'upsertChemicalElement';
export const DELETE_CHEMICAL_ELEMENT_KEY = 'deleteChemicalElement';

// Chemical Formulation
export const GET_CHEMICAL_FORMULATIONS_KEY = 'chemicalFormulations';
export const UPSERT_CHEMICAL_FORMULATION_KEY = 'upsertChemicalFormulation';
export const DELETE_CHEMICAL_FORMULATION_KEY = 'deleteChemicalFormulation';

// Chemical Product Active Ingredient
export const GET_CHEMICAL_PRODUCT_ACTIVE_INGREDIENTS_KEY = 'chemicalProductActiveIngredients';
export const UPSERT_CHEMICAL_PRODUCT_ACTIVE_INGREDIENT_KEY = 'upsertChemicalProductActiveIngredient';
export const DELETE_CHEMICAL_PRODUCT_ACTIVE_INGREDIENT_KEY = 'deleteChemicalProductActiveIngredient';

// Chemical Product Element
export const GET_CHEMICAL_PRODUCT_ELEMENTS_KEY = 'chemicalProductElements';
export const UPSERT_CHEMICAL_PRODUCT_ELEMENT_KEY = 'upsertChemicalProductElement';
export const DELETE_CHEMICAL_PRODUCT_ELEMENT_KEY = 'deleteChemicalProductElement';

// Chemical Product
export const GET_CHEMICAL_PRODUCTS_KEY = 'chemicalProducts';
export const UPSERT_CHEMICAL_PRODUCT_KEY = 'upsertChemicalProduct';
export const DELETE_CHEMICAL_PRODUCT_KEY = 'deleteChemicalProduct';

// Safety Precaution
export const GET_SAFETY_PRECAUTIONS_KEY = 'safetyPrecautions';
export const UPSERT_SAFETY_PRECAUTION_KEY = 'upsertSafetyPrecaution';
export const DELETE_SAFETY_PRECAUTION_KEY = 'deleteSafetyPrecaution';

// Safety Precaution PPE
export const GET_SAFETY_PRECAUTION_PPES_KEY = 'safetyPrecautionPPEs';
export const UPSERT_SAFETY_PRECAUTION_PPE_KEY = 'upsertSafetyPrecautionPpe';
export const DELETE_SAFETY_PRECAUTION_PPE_KEY = 'deleteSafetyPrecautionPpe';

/*================================================================================================================
*                                             Dev Tools Master Data
* ==============================================================================================================*/

// Logging
export const GET_LOGS_KEY = 'logs';
export const SET_PACKMAN_FARM_SYNC_STATUS_KEY = 'setPackmanFarmSyncStatus';
export const TRIGGER_PACKMAN_FARM_SYNC_KEY = 'triggerPackmanFarmSync';

/*================================================================================================================
*                                                    Fresh Service
* ==============================================================================================================*/

export const UPSERT_FRESH_SERVICE_TICKET_KEY = 'upsertFreshServiceTicket';

/*================================================================================================================
*                                                    Pack Man
* ==============================================================================================================*/

export const GET_PACKMAN_INTAKE_SUMMARIES_KEY = 'intakeSummaries';

/*================================================================================================================
*                                                    Season
* ==============================================================================================================*/

// Season Program
export const GET_SEASON_PROGRAMS_KEY = 'seasonPrograms';
export const UPSERT_SEASON_PROGRAM_KEY = 'upsertSeasonProgram';
export const UPSERT_SEASON_PROGRAM_COPY_KEY = 'upsertSeasonProgramCopy';
export const DELETE_SEASON_PROGRAM_KEY = 'deleteSeasonProgram';

// Season Program Line
export const GET_SEASON_PROGRAM_LINES_KEY = 'seasonProgramLines';
export const UPSERT_SEASON_PROGRAM_LINE_KEY = 'upsertSeasonProgramLine';
export const DELETE_SEASON_PROGRAM_LINE_KEY = 'deleteSeasonProgramLine';

/*================================================================================================================
*                                                    Season
* ==============================================================================================================*/

export const SET_PACKMAN_FARM_SYNC_STATUS = 'setStatus';
export const TRIGGER_PACKMAN_FARM_FULL_SYNC = 'triggerFullSync';

/*================================================================================================================
*                                          Frequently Asked Questions
* ==============================================================================================================*/

// FrequentlyAskedQuestion
export const GET_FREQUENTLY_ASKED_QUESTIONS_KEY = 'frequentlyAskedQuestion';
export const UPSERT_FREQUENTLY_ASKED_QUESTION_KEY = 'upsertFrequentlyAskedQuestion';
export const DELETE_FREQUENTLY_ASKED_QUESTION_KEY = 'deleteFrequentlyAskedQuestion';
