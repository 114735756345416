import axios from 'axios';
import { IBlock } from '../../../@types/model/masterData/block/block';
import { IBlockHomeView } from '../../../@types/model/masterData/block/blockHomeView';
import { IBlockUpsert } from '../../../@types/model/masterData/block/blockUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class BlockHttpService {
    public static getList = async (
        lastSyncedTimeUnix : number,
        departmentIds : Array<number>,
        divisionId : number | null,
        fieldId : number | null,
        projectId : number | null,
        signal ?: AbortSignal
    ) : Promise<Array<IBlock>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Block/GetList`, {
            params: {
                lastSyncedTimeUnix,
                departmentIds,
                divisionId,
                fieldId,
                projectId,
            },
            paramsSerializer: {
                indexes: null
            },
            signal
        });

        return result.data;
    }

    public static getBlockHomeViewList = async (signal ?: AbortSignal) : Promise<Array<IBlockHomeView>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Block/GetBlockHomeViewList`, {
            signal
        });
        return result.data;
    }

    public static getBlock = async (blockId : number, signal ?: AbortSignal) : Promise<IBlock> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Block/GetBlock`, {
            params: { blockId },
            signal
        });
        return result.data;
    }

    public static upsert = async (upsert : Array<IBlockUpsert>, type : UpsertTypeEnum) : Promise<Array<IBlock>> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Block/${type}`, upsert);

        return result.data;
    };

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Block/Delete`, {
            params: { id },
        });
    };
}